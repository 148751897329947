/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

@keyframes blink {
  0% { background-color: white; }
  50% { background-color: rgb(134, 253, 70); }
  100% { background-color: white; }
}

.blink {
  animation: blink 1s infinite;
}
@font-face {
  /* font-family: 'NotoNastaliqUrdu'; */
  font-family: 'NotoNastaliqUrdu', sans-serif; /* Add the proper font family */
  src: url('/public/NotoNastaliqUrdu.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.urduNotationFont{
  line-height: 2.4;
  font-family: 'NotoNastaliqUrdu', sans-serif;
}

.online-appointment {
  position: relative;
  background: linear-gradient(90deg, rgba(34, 197, 94, 0.1) 0%, rgba(255, 255, 255, 1) 50%, rgba(34, 197, 94, 0.1) 100%);
  background-size: 200% 100%;
  animation: glowAnimation 3s linear infinite;
  border-left: 4px solid #22c55e;
}

.online-appointment {
  background-color: rgba(34, 197, 94, 0.2) !important; /* Increased hover color visibility */
  transition: background-color 0.3s ease;
}

@keyframes glowAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
/* @font-face {
  font-family: 'Noto Nastaliq Urdu';
  src: url('../../hims-frontend/public/NotoNastaliqUrdu.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */
@font-face {
  font-family: 'Jameel Noori';
  src: url('data:font/ttf;base64,${urduFont}') format('truetype');
}

@media print {
  .page-break {
    page-break-before: always;
  }
}

